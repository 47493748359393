.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  background: black;
}

.innerContainer {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 50px);
  max-height: 100%;
}

.topText {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  margin: 4px;
}

.bottomText {
  font-size: 12px;
  padding: 0;
  margin: 4px;
}

.topImage {
  max-height: 40%;
  max-width: 100%;
  width: auto;
  object-fit: contain;
}

.bottomImage {
  max-height: 40%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.sadImage {
  max-height: 20%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.jordiImage {
  max-height: 40%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  border: 8px solid rgb(219, 219, 219);
}

p {
  padding: 16px;
  color: white;
  text-align: center;
}
/* ------------------------------- */

#btn {
  background: #222;
  height: 50px;
  min-width: 250px;
  width: 250px;
  border: none;
  border-radius: 10px;
  color: #eee;
  font-size: 20px;
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
}

#btn #circle {
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transition: 500ms;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#btn:hover {
  background: rgb(97, 97, 97);
}

#btn:hover #circle {
  height: 50px;
  width: 240px;
  left: 5px;
  border-radius: 0;
  border-bottom: 2px solid #eee;
}

/* ----------------- */

.wrapper {
  display: inline-flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.wrapper .option {
  background: rgb(228, 228, 228);
  height: 100%;
  max-width: 200px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #488b4d;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  background: #488b4d;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 16px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}
